<template>
  <v-dialog
    content-class="dialog-rounded"
    v-model="showAdd"
    width="500"
    persistent
    scrollable
    @keydown.esc="onClose"
    @keydown.enter="save"
    :retain-focus="false"
  >
    <template v-slot:activator="{ on }">
      <general-button @click="onNewClicked" :action="on.click" small>
        <prosk-icon icon="edit-black" /> {{ $t("createJob") }}
      </general-button>
    </template>

    <details-card scrollable>
      <v-card-title class="text-h5 bg-prosk-secondary white--text">
        <prosk-title class="headline" v-if="item.isClient">
          {{ $t("jobDetail") }}
          <v-chip class="white--text" :color="getColorClass(item.status)">
            {{ getStatusName(item.status) }}
          </v-chip>
        </prosk-title>
        <prosk-title class="headline" v-else>
          {{ item.isEdit ? $t("editJob") : $t("createJob") }}
          <v-chip v-if="item.isEdit" class="white--text" :color="getColorClass(item.status)">
            {{ getStatusName(item.status) }}
          </v-chip>
        </prosk-title>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row v-if="!item.isEdit">
            <v-col cols="12">
              <custom-auto-complete
                :label="$t('predefinedService')"
                v-model="predefinedService"
                :items="services"
                item-text="name"
                item-value="id"
              ></custom-auto-complete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <custom-text-field
                :label="$t('title')"
                v-model="title"
                :disabled="item.isClient"
                :hide-details="titleErrors.length === 0"
                :error-count="titleErrors.length"
                :error-messages="titleErrors"
              ></custom-text-field>
            </v-col>
            <v-col cols="12">
              <custom-text-field
                textarea
                rows="1"
                row-height="15"
                auto-grow
                :disabled="item.isClient"
                :hide-details="descriptionErrors.length === 0"
                :error-count="descriptionErrors.length"
                :error-messages="descriptionErrors"
                :label="$t('description')"
                v-model="description"
              />
            </v-col>
            <v-col cols="12">
              <custom-text-field
                type="number"
                :label="$t('price')"
                :disabled="item.isClient"
                min="0"
                :hide-details="priceErrors.length === 0"
                :error-count="priceErrors.length"
                :error-messages="priceErrors"
                v-model="price"
              ></custom-text-field>
            </v-col>
            <v-col md="6" cols="12" class="text-center">
              <span class="font-weight-bold black--text">
                {{ $t("startDate") }}
              </span>
              <v-date-picker
                v-model="start_date"
                locale="es"
                color="#59D79D"
                :disabled="item.isClient"
                show-current
                :min="new Date().toISOString().substr(0, 10)"
                full-width
                no-title
              ></v-date-picker>
            </v-col>
            <v-col md="6" cols="12" class="text-center">
              <span class="font-weight-bold black--text">
                {{ $t("endDate") }}
              </span>
              <v-date-picker
                v-model="end_date"
                locale="es"
                :disabled="item.isClient"
                color="#59D79D"
                show-current
                :min="new Date().toISOString().substr(0, 10)"
                full-width
                no-title
              ></v-date-picker>
            </v-col>
            <span class="red--text"> {{ dateErrors }}</span>
            <span class="red--text"> {{ errors }}</span>
            <v-col cols="12" v-if="!selectedUser">
              <v-autocomplete
                :label="$t('client')"
                :items="clients"
                v-model="client"
                class="rounded-input-autocomplete"
                outlined
                chips
                :disabled="item.isClient"
                :hide-details="clientErrors.length === 0"
                :error-count="clientErrors.length"
                :error-messages="clientErrors"
                :search-input.sync="searchInput"
                :onChange="() => searchInput = ''"
                solo
              >
                <template #selection="{ item }">
                  <v-chip color="primary_light" class="main_text_color--text">{{item.first_name + " " + item.last_name}}</v-chip>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="payment_required"
                :label="$t('paymentRequired')"
                color="#59D79D"
                :disabled="item.isClient || !showJobs"
                hide-details
              ></v-checkbox>
              <span v-show="!showJobs">{{ $t('linkYourMercadoPagoAccountToMakePaymentRequired') }}</span>
            </v-col>
            <v-col cols="12" v-if="isAdmin">
                <span>Prosker:
                    {{item.prosker_user ? item.prosker_user.first_name + ' ' + item.prosker_user.last_name : ''}}
                </span>
                <br>
                <span>{{$t('reasonHelp')}}:{{item.reason_help}}</span>
            </v-col>
            <v-col cols="12" v-if="item.isClient && item.status === 70">
              <span>
                {{ $t("tellUsHowItWentWith") }}:
                {{ item.prosker_user.first_name }}
                {{ item.prosker_user.last_name }}
              </span>
              <custom-text-field
                textarea
                rows="1"
                row-height="15"
                auto-grow
                :hide-details="reviewErrors.length === 0"
                :error-count="reviewErrors.length"
                :error-messages="reviewErrors"
                :label="$t('review')"
                v-model="review"
              />
            </v-col>
            <v-col cols="12" v-if="item.isClient && item.status === 70">
              <rating
                v-model="rating"
                background-color="warning lighten-1"
                half-increments
                color="warning"
                hover
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="dialog-actions">
        <v-spacer></v-spacer>
        <general-button
          :action="onClose"
          :message="$t('cancel')"
          outlined
          v-if="!item.isClient || isAdmin"
        />
        <general-button
          :action="save"
          :loading="loading"
          :message="$t('save')"
          primary
          v-if="!item.isClient"
        />
        <general-button
          :action="saveReview"
          :loading="loadingReview"
          :message="$t('save')"
          primary
          v-if="item.isClient && item.status === 70"
        />
      </v-card-actions>
    </details-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import { mapGetters } from 'vuex';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import GeneralButton from '@/components/socialvue/buttons/GeneralButton.vue';
import _ from 'lodash';
import getStatusJobsName from '@/components/socialvue/mixins/getStatusJobsName';
import userDataMixin from '@/mixins/userDataMixin';
import ProskIcon from '@/icons/ProskIcon.vue';
import DetailsCard from '../cards/DetailsCard.vue';
import ProskTitle from '../markup/ProskTitle.vue';
import CustomTextField from '../customTextField/CustomTextField.vue';
import CustomAutoComplete from '../customAutocomplete/CustomAutoComplete.vue';
import Rating from '../profile/rating.vue';

export default {
  name: 'JobsDialog',
  components: {
    CustomTextField,
    CustomAutoComplete,
    Rating,
    GeneralButton,
    ProskIcon,
    DetailsCard,
    ProskTitle
  },
  props: ['showAdd',
    'onClose',
    'item',
    'onAdded',
    'newItem',
    'selectedUser',
    'isAdmin',
    'showAlert',
    'showJobs'
  ],
  mixins: [errorDialog, getStatusJobsName, userDataMixin],
  data () {
    return {
      services: [],
      predefinedService: null,
      client: null,
      review: null,
      rating: 0,
      id: null,
      clients: [],
      errors: null,
      titleErrors: [],
      dateErrors: null,
      descriptionErrors: [],
      reviewErrors: [],
      priceErrors: [],
      clientErrors: [],
      end_date: null,
      searchInput: '',
      start_date: null,
      price: 0,
      isLoading: false,
      end_date_format: null,
      start_date_format: null,
      description: null,
      title: null,
      loading: false,
      loadingReview: false,
      payment_required: false
    };
  },
  watch: {
    showAdd: function (value) {
      if (value && this.item && this.item.isEdit) {
        this.client = this.item.client_user.id;
        this.id = this.item.id;
        this.price = this.item.price;
        const originalStartDate = new Date(this.item.start_date);
        this.start_date = moment(moment.utc(originalStartDate)).format(
          'YYYY-MM-DD'
        );
        const originalEndDate = new Date(this.item.end_date);
        this.end_date = moment(moment.utc(originalEndDate)).format(
          'YYYY-MM-DD'
        );
        this.description = this.item.description;
        this.title = this.item.title;
        this.payment_required = this.item.payment_required;
        this.isEdit = this.item.isEdit;
      }
      if (value && this.selectedUser) {
        this.client = this.selectedUser;
      }
    },
    searchInput: function () {
      this.getClientsData(this);
    },
    predefinedService: function () {
      const selectedService = this.services.find(
        (service) => service.id === this.predefinedService
      );

      if (selectedService) {
        this.title = selectedService.name;
        this.description = selectedService.description;
        this.price = selectedService.price;
        this.payment_required = selectedService.payment_required;
      }
    }
  },
  created () {
    this.getReview();
    this.getPredefinedServices();
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  methods: {
    onNewClicked () {
      this.newItem();
      this.resetErrors();
      this.reset();
    },
    getClientsData: _.debounce((self) => {
      self.isLoading = true;
      const url = (`${process.env.VUE_APP_BASE_URL}/api/users?search=${self.searchInput === null
          ? '' : self.searchInput}&page=1`).trim();
      axios.get(url).then((response) => {
        self.clients = [];
        response.data.data.forEach((client) => {
          if (client.id !== self.currentUser.id) {
            client.value = client.id;
            client.text = client.full_name;
            self.clients.push(client);
          }
        });
        console.log('clients test;, ', self.clients[0].text);
        self.isLoading = false;
      }).catch((error) => {
        self.showError(error);
        self.isLoading = false;
      });
    }, 500),
    getPredefinedServices () {
      const url = `${process.env.VUE_APP_BASE_URL}/api/services?filters=user_id=${this.currentUser.id}`;
      axios.get(url).then((response) => {
        this.services = response.data.data;
      });
    },
    reset () {
      if (!this.selectedUser) {
          this.client = null;
      }
      this.review = null;
      this.end_date = null;
      this.start_date = null;
      this.price = 0;
      this.end_date_format = null;
      this.start_date_format = null;
      this.description = null;
      this.title = null;
      this.payment_required = false;
    },
    resetErrors () {
      this.errors = null;
      this.dateErrors = null;
      this.titleErrors = [];
      this.descriptionErrors = [];
      this.reviewErrors = [];
      this.clientErrors = [];
      this.priceErrors = [];
    },
    save () {
      this.resetErrors();

      let error = false;

      const originalStartDate = new Date(this.start_date);
      const formattedStartDate = moment(moment.utc(originalStartDate)).format(
          'DD-MM-YYYY'
      );
      const originalEndDate = new Date(this.end_date);
      const formattedEndDate = moment(moment.utc(originalEndDate)).format(
          'DD-MM-YYYY'
      );
      if (this.price < 0) {
        error = true;
        this.priceErrors.push(this.$t('onlyIntegerPrices'));
      }
      if (originalStartDate > originalEndDate) {
        error = true;
        this.errors = this.$t('endDateMustBeLaterThanStartDate');
      }
      if (!this.title) {
        error = true;
        this.titleErrors.push(this.$t('fieldTitleRequired'));
      }
      if (!this.description) {
        error = true;
        this.descriptionErrors.push(this.$t('fieldDescriptionRequired'));
      }
      if (this.payment_required && this.price === 0) {
        error = true;
        this.priceErrors.push(this.$t('fieldPriceRequired'));
      }
      if (this.price > 0 && this.price < 151) {
        error = true;
        this.priceErrors.push(this.$t('priceMustBeBiggerThan150'));
      }
      if (!this.start_date || !this.end_date) {
        error = true;
        this.dateErrors = this.$t('startOrEndDateRequired');
      }
      if (!this.client) {
        error = true;
        this.clientErrors.push(this.$t('fieldClientRequired'));
      }
      if (!error) {
        const formData = {
          client_user_id: this.client,
          prosker_user_id: this.currentUser.id,
          end_date: formattedEndDate,
          start_date: formattedStartDate,
          price: this.price,
          description: this.description,
          title: this.title,
          payment_required: this.payment_required
        };
        const url = `${process.env.VUE_APP_BASE_URL}/api/jobs`;
        if (!this.item.isEdit) {
          this.loading = true;
          axios
            .post(url, formData)
            .then((response) => {
              this.onClose();
              this.onAdded();
              if (this.showAlert) {
                this.$swal.fire({
                  title: this.$t('awesome'),
                  text: this.$t('jobCreatedSuccessfully'),
                  confirmButtonColor: '#19AD93'
                });
              }
              const responseData = response.data.data[0];
              this.trackUserData('add_to_cart', {
                  ecommerce: {
                    currency: process.env.VUE_APP_LOCATION.includes('br') ? 'BRL' : 'UYU',
                    value: responseData.price,
                    items: [
                      {
                        item_id: responseData.id,
                        item_name: formData.title,
                        price: responseData.price,
                        quantity: 1
                      }
                    ],
                    ProductIDList: [responseData.id]
                  }
              });
              this.reset();
              this.resetErrors();
            })
            .catch((error) => {
              this.showError(error);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.loading = true;
          const url =
            process.env.VUE_APP_BASE_URL + '/api/jobs/' + this.item.id;
          axios
            .put(url, formData)
            .then(() => {
              this.loading = false;
              this.onClose();
              this.onAdded();
              this.reset();
              this.resetErrors();
            })
            .catch((error) => {
              this.loading = false;
              this.showError(error);
            });
        }
      }
    },
    getReview () {
      this.review = '';
      this.rating = 0;
      const url = `${
        process.env.VUE_APP_BASE_URL
      }/api/reviews?filters=job_id=${2}`;
      axios.get(url).then((response) => {
        response.data.data.forEach((review) => {
          this.review = review.content;
          this.rating = review.rating;
        });
      });
    },
    saveReview () {
      this.resetErrors();
      if (this.review === '') {
        this.reviewErrors.push(this.$t('fieldReviewRequired'));
      } else {
        const formData = {
          title: this.title,
          content: this.review,
          rating: this.rating,
          job_id: this.item.id
        };
        const url = `${process.env.VUE_APP_BASE_URL}/api/reviews`;
        this.loadingReview = true;
        axios
          .post(url, formData)
          .then(() => {
            this.onClose();
            this.onAdded();
            this.loadingReview = false;
          })
          .catch((error) => {
            this.loadingReview = false;
            this.showError(error);
          });
      }
    }
  }
};
</script>
<style scoped>
  h3.headline {
    margin: 0;
    padding: 0 9px;
  }
</style>
